import TwoWheelerIcon from '../../assets/delivery-boy.png';
// import ScheduleDeliveryIcon from '../../assets/delivery-schedule.png'
import PackersAndMoversIcon from '../../assets/packersAndMovers.png'
import TruckIcon from '../../assets/delivery-truck.png'
import UserIcon from '../../assets/user-engagement.png'
import DriverIcon from '../../assets/driver.png'

export const ServicesCardDetails = [
    {
        title: 'By Two wheelers',
        description: 'For small parcels, opt for two-wheeler delivery for quick and easy service. Have a two-wheeler? Sign up as a driver and start earning today!',
        points: [
            'Best choice for small packages',
            'Fast and efficient service',
            'Be a driver and start delivering'
        ],
        icon: TwoWheelerIcon,
    },
    {
        title: 'By Trucks',
        description: 'For larger deliveries, choose our truck service to move your goods with ease and reliability. Own a truck? Join us as a driver and start transporting today!',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ],
        icon: TruckIcon,
    },
    // {
    //     title: 'Schedule Delivery',
    //     description: 'Schedule your delivery at your convenience. Choose a time that works for you and ensure a seamless pickup and drop-off.',
    //     points: [
    //         'Lorem ipsum dolor',
    //         'Lorem ipsum dolor',
    //         'Lorem ipsum dolor'
    //     ],
    //     icon: ScheduleDeliveryIcon,
    // },
    {
        title: 'Packers And Movers',
        description: 'Book a vehicle for your move and select the number of days you need. Enjoy flexible scheduling and hassle-free relocation services.',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ],
        icon: PackersAndMoversIcon,
    }
]

export const JoinUsDetails = [
    {
        title: 'As Driver',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor repudiandae debitis repellat eius laboriosam iusto itaque consectetur nobis blanditiis omnis.',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ],
        icon: DriverIcon
    },
    {
        title: 'As User',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor repudiandae debitis repellat eius laboriosam iusto itaque consectetur nobis blanditiis omnis.',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ],
        icon: UserIcon
    }
]