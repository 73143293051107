export const NAV_LINKS =[
    {
        label: 'Home',
        link: ''
    },
    {
        label: 'Services',
        link: 'services'
    },
    {
        label: 'Join Us',
        link: 'joinUs'
    },
]